/**
 * @name: 系统管理-软件更新接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 系统管理-软件更新接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPage, IPageRes} from "@/apis/page";
import type { IUpdate } from "./types";

/**
 * 版本更新分页查询
 * @param param
 * @returns
 */
export const appVersionQueryApi = (param: IPage) => get<IPageRes<IUpdate[]>>("/admin/appVersion/query", param)
/**
 * 版本更新详情
 * @param id
 * @returns
 */
export const appVersionDetailApi = (id: string) => get<IUpdate>("/admin/appVersion/detail/" + id)
/**
 * 版本创建
 * @param data
 * @returns
 */
export const appVersionCreateApi = (data: IUpdate) => postJ("/admin/appVersion/create", data)
