
/**
 * @name: 系统管理-软件更新
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 系统管理-软件更新
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { appVersionQueryApi, appVersionDetailApi, appVersionCreateApi } from "@/apis/systemManage/update"
import type { IUpdate } from "@/apis/systemManage/update/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import type { IPage } from "@/apis/page";
import { deepCopy } from "@/utils/common";

@Component({})
export default class systemManageUpdate extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IUpdate[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPage = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IUpdate> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    menu: false,
    dialogWidth: '600px',
    column: [
      {
        label: "ID",
        prop: "id",
        width: 180,
        align: "left",
        addHide: true,
        slot: true
      },
      {
        label: "版本号",
        prop: "versionCode",
        align: "center",
        rules: [
          {required: true, message: "请输入版本号", trigger: "blur"}
        ],
        width: 100
      },
      {
        label: "版本名称",
        prop: "versionName",
        align: "center",
        rules: [
          {required: true, message: "请输入版本号", trigger: "blur"}
        ],
        width: 100
      },
      {
        label: "安装包",
        prop: "versionUrl",
        align: "center",
        addSlot: true,
        rules: [
          {required: true, message: "请输入版本号", trigger: "blur"}
        ]
      },
      {
        label: "备注",
        prop: "versionRemark",
        overHidden: true,
        maxlength: 50,
        rules: [
          {required: true, message: "请输入备注", trigger: "blur"}
        ]
      }
    ]
  }

  getList () {
    this.tableLoading = true
    appVersionQueryApi(this.queryParam).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  rowSave (form: IUpdate, done: Function, loading: Function) {
    const data: IUpdate = deepCopy(form)
    if (data.versionUrl) {
      data.versionUrl = `${location.protocol}//${location.hostname}${location.port ? (':' + location.port) : ''}/api/party/oss/download${form.versionUrl}`
    }
    appVersionCreateApi({...data, appType: 2}).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  created () {
    this.getList()
  }
}
